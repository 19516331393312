import React from "react";
import Cubas from "../components/Cubas/Cubas";

const CubasPage = () => {
  return (
    <>
      <Cubas />
    </>
  );
};

export default CubasPage;
