import React from "react";
import { Content } from "../components/Content/Content";
import { heroMissao, heroVisao, heroValores } from "../data/HeroData";

const Sobre = () => {
  return (
    <>
      <Content {...heroMissao} />
      <Content {...heroVisao} />
      <Content {...heroValores} />
    </>
  );
};

export default Sobre;
