import { ContentSocialIcon } from "../components/Content/ContentStyles";
import { FooterSocialIcon } from "../components/Footer/FooterStyles";
import { Row, Section } from "../globalStyles";
import { footerSocialData } from "./FooterData";

export const heroAbout = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Resitech",
  },
  headline: "Sobre nós",
  description:
    "A Resitech Solid Surface tem como objetivo principal oferecer produtos e prestar serviços de qualidade, possibilitando desenvolver projetos e acabamento em alto padrão, sobre quaisquer estruturas e superfícies",
  buttonLabel: "Conheça a Resitech",

  linkTo: "/sobre",
  imgStart: "",
  img: "./assets/logos/Logo_01.jpg",
  start: "true",
};

export const heroContact = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Resitech",
  },
  headline: "Contato",
  description: (
    <Section padding="1rem 0 2rem 0">
      {" Fale conosco através das nossas redes sociais"}
      <br></br>

      {footerSocialData.map((social, index) => (
        <ContentSocialIcon
          key={index}
          href={social.link}
          target="_blank"
          aria-label={social.name}
        >
          {social.icon}
        </ContentSocialIcon>
      ))}
    </Section>
  ),
  buttonLabel: "Fale conosco",

  linkTo: "/contato",
  imgStart: "start",
  img: "./assets/inspiracoes/banheiro/Banheiro_04.jpg",
  start: "true",
};

export const heroOne = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Superfície Sólida Mineral",
  },
  headline: "Durável",
  description:
    "Mantém sua boa aparência e resiste aos impactos e riscos que podem ocorrer com desgaste diário",
  buttonLabel: "Ver inspirações",

  linkTo: "/inspiracoes",
  imgStart: "start",
  img: "./assets/inspiracoes/cozinha/Cozinha_03.jpg",
  start: "true",
};

export const heroTwo = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Superfície Sólida Mineral",
  },
  headline: "Resistente",
  description:
    "Não delamina. Com emendas imperceptíveis, os líquidos não penetram na superfície",
  buttonLabel: "Ver inspirações",

  linkTo: "/inspiracoes",
  imgStart: "start",
  img: "./assets/inspiracoes/cozinha/Cozinha_13.jpg",
  start: "true",
};

export const heroThree = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Superfície Sólida Mineral",
  },
  headline: "Fácil de limpar",
  description:
    "É uma superfície não porosa, que evita que resíduos e manchas penetrem no material",
  buttonLabel: "Ver inspirações",

  linkTo: "/inspiracoes",
  imgStart: "",
  img: "./assets/inspiracoes/banheiro/Banheiro_02.jpg",
  start: "true",
};

export const heroFour = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Superfície Sólida Mineral",
  },
  headline: "Não poroso",
  description:
    "Sua superfície lisa não permite que resíduos penetrem, e isso facilita a limpeza. Bactérias e fungos também não penetram",
  buttonLabel: "Ver inspirações",

  linkTo: "/inspiracoes",
  imgStart: "start",
  img: "./assets/inspiracoes/cozinha/Cozinha_12.jpg",
  start: "true",
};

export const heroFive = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Superfície Sólida Mineral",
  },
  headline: "Reparável",
  description:
    "Manchas e arranhões podem ser facilmente removidos usando uma esponja e um produto de limpeza comum",
  buttonLabel: "Ver inspirações",

  linkTo: "/inspiracoes",
  imgStart: "start",
  img: "./assets/inspiracoes/banheiro/Banheiro_03.jpg",
  start: "true",
};

export const heroSix = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Superfície Sólida Mineral",
  },
  headline: "Emendas imperceptíveis",
  description:
    "Os adesivos usados para colar a superfície propcia um acabamento com emendas imperceptíveis",
  buttonLabel: "Ver inspirações",

  linkTo: "/inspiracoes",
  imgStart: "start",
  img: "./assets/inspiracoes/cozinha/Cozinha_09.jpg",
  start: "true",
};

export const heroSeven = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Superfície Sólida Mineral",
  },
  headline: "Sustentável",
  description:
    "Livre de produtos químicos perigosos e com compostos orgânicos voláteis muito baixos para contribuir para um ar mais limpo",
  buttonLabel: "Ver inspirações",

  linkTo: "/inspiracoes",
  imgStart: "start",
  img: "./assets/inspiracoes/cozinha/Cozinha_17.jpg",
  start: "true",
};

export const heroMissao = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Resitech",
  },
  headline: "MISSÃO",
  description:
    "Trabalhar com agilidade, satisfação e profissionalismo. Prestar serviços de qualidade á um preço justo. Fazer parte de uma rede de relacionamento virtuosa. Ter funcionários satisfeitos, bem treinados, com postura, que colaborem para um resultado técnico de qualidade, em um ambiente digno e sadio.",
  buttonLabel: "Find More",
  imgStart: "start",
  img: "./assets/inspiracoes/cozinha/Cozinha_02.jpg",
  start: "true",
};

export const heroVisao = {
  reverse: false,
  inverse: false,
  topLine: {
    text: "Resitech",
  },
  headline: "VISÃO",
  description:
    "Coexistir e crescer em parcerias com empresas sérias, que estejam dispostas a profissionalizar nosso mercado. Focada na qualidade de atendimento e serviços. Colaborativa, confiável, tecnicamente adequada e justa.",
  buttonLabel: "View Project",

  linkTo: "/inspiracoes",
  imgStart: "start",
  img: "./assets/inspiracoes/cozinha/Cozinha_10.jpg",
  start: "true",
};

export const heroValores = {
  reverse: true,
  inverse: true,
  topLine: {
    text: "Resitech",
  },
  headline: "VALORES",
  description:
    "Respeito às pessoas e ao ambiente de trabalho. Segurança de funcionários, clientes e terceiros. Padrões de ética; relacionamento ágil, claro, responsável, preciso e confiável. Baseado em responsabilidade e confiança mútua; gerenciamento em equipe, consistente e focado.",
  buttonLabel: "View Project",

  linkTo: "/inspiracoes",
  imgStart: "start",
  img: "./assets/inspiracoes/banheiro/Banheiro_05.jpg",
  start: "true",
};
