import React from "react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
  {
    name: "Instagram",
    icon: iconStyle(FaInstagram),
    link: "https://www.instagram.com/resitech_/",
  },
  {
    name: "Whatsapp",
    icon: iconStyle(FaWhatsapp),
    link: "http://api.whatsapp.com/send?1=pt_BR&phone=5511974170964",
  },
  {
    name: "Email",
    icon: iconStyle(FiMail),
    link: "mailto:comercial@resitechsolidsurface.com.br",
  },
];
