import React from "react";
import Form from "../components/Form/Form";

const Contato = () => {
  return (
    <>
      <Form />
    </>
  );
};

export default Contato;
