import React from "react";

import { BsFillShieldLockFill } from "react-icons/bs";
import { IoIosOptions } from "react-icons/io";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiSupport, BiDollar } from "react-icons/bi";
import { GrHostMaintenance } from "react-icons/gr";
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const cubasData = [
  {
    name: "RT-002",
    type: "Cubas",
    imgClass: "1",

    image: "./assets/cubas/RT-002.jpg",
  },
  {
    name: "RT-002-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-002.jpg",
  },
  {
    name: "RT-005",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-005.jpg",
  },
  {
    name: "RT-005-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-005.jpg",
  },
  {
    name: "RT-008",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-008.jpg",
  },
  {
    name: "RT-008-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-008.jpg",
  },
  {
    name: "RT-009",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-009.jpg",
  },
  {
    name: "RT-009-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-009.jpg",
  },
  {
    name: "RT-020",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-020.jpg",
  },
  {
    name: "RT-020-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-020.jpg",
  },
  {
    name: "RT-120",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-120.jpg",
  },
  {
    name: "RT-120-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-120.jpg",
  },
  {
    name: "RT-220",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-220.jpg",
  },
  {
    name: "RT-220-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-220.jpg",
  },
  {
    name: "RT-290",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-290.jpg",
  },
  {
    name: "RT-290-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-290.jpg",
  },
  {
    name: "RT-315",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-315.jpg",
  },
  {
    name: "RT-315-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-315.jpg",
  },
  {
    name: "RT-340",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-340.jpg",
  },
  {
    name: "RT-340-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-340.jpg",
  },
  {
    name: "RT-347",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-347.jpg",
  },
  {
    name: "RT-347-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-347.jpg",
  },
  {
    name: "RT-370",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-370.jpg",
  },
  {
    name: "RT-370-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-370.jpg",
  },
  {
    name: "RT-400",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-400.jpg",
  },
  {
    name: "RT-400-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-400.jpg",
  },
  {
    name: "RT-416",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-416.jpg",
  },
  {
    name: "RT-416-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-416.jpg",
  },
  {
    name: "RT-470",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-470.jpg",
  },
  {
    name: "RT-470-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-470.jpg",
  },
  {
    name: "RT-570",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-570.jpg",
  },
  {
    name: "RT-570-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-570.jpg",
  },
  {
    name: "RT-620",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-620.jpg",
  },
  {
    name: "RT-620-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-620.jpg",
  },
  {
    name: "RT-700",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-700.jpg",
  },
  {
    name: "RT-700-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-700.jpg",
  },
  {
    name: "RT-725",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-725.jpg",
  },
  {
    name: "RT-725-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-725.jpg",
  },
  {
    name: "RT-730",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-730.jpg",
  },
  {
    name: "RT-730-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-730.jpg",
  },
  {
    name: "RT-732",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-732.jpg",
  },
  {
    name: "RT-732-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-732.jpg",
  },
  {
    name: "RT-736",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-736.jpg",
  },
  {
    name: "RT-736-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-736.jpg",
  },
  {
    name: "RT-737",
    type: "Cubas",
    imgClass: "1",
    image: "./assets/cubas/RT-737.jpg",
  },
  {
    name: "RT-737-E",
    type: "Especificações",
    imgClass: "2",
    image: "./assets/especificacoes/RT-737.jpg",
  },
];
