import React from "react";

import { BsFillShieldLockFill } from "react-icons/bs";
import { IoIosOptions } from "react-icons/io";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiSupport, BiDollar } from "react-icons/bi";
import { GrHostMaintenance } from "react-icons/gr";
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const cozinhaData = [
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_01.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_02.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_03.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_04.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_05.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_06.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_07.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_08.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_09.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_10.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_11.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_12.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_13.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_14.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_15.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_16.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_17.jpg",
  },
  {
    name: "RT-002",
    type: "Cozinha",
    imgClass: "1",

    image: "./assets/inspiracoes/cozinha/Cozinha_18.jpg",
  },
];
export const banheiroData = [
  {
    name: "RT-002",
    type: "Banheiro",
    imgClass: "1",

    image: "./assets/inspiracoes/banheiro/Banheiro_01.jpg",
  },
  {
    name: "RT-002",
    type: "Banheiro",
    imgClass: "1",

    image: "./assets/inspiracoes/banheiro/Banheiro_02.jpg",
  },
  {
    name: "RT-002",
    type: "Banheiro",
    imgClass: "1",

    image: "./assets/inspiracoes/banheiro/Banheiro_03.jpg",
  },
  {
    name: "RT-002",
    type: "Banheiro",
    imgClass: "1",

    image: "./assets/inspiracoes/banheiro/Banheiro_04.jpg",
  },
  {
    name: "RT-002",
    type: "Banheiro",
    imgClass: "1",

    image: "./assets/inspiracoes/banheiro/Banheiro_05.jpg",
  },
];
