import React from "react";
import { Container, Section } from "../../globalStyles";
import {
  CubaText,
  CubaTitle,
  CubaWrapper,
  CubaColumn,
  CubaImageWrapper,
  CubaName,
  CubaTextWrapper,
  ImageWrapper,
  CarouselImage,
} from "./CubasStyles";
import { cubasData } from "../../data/CubasData";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Cubas = () => {
  const initial = {
    y: 40,
    opacity: 0,
  };
  const animate = {
    y: 0,
    opacity: 1,
  };

  return (
    <Section smPadding="100px 10px" position="relative" inverse id="about">
      <Container>
        <CubaTextWrapper>
          <CubaTitle>Cubas</CubaTitle>
        </CubaTextWrapper>
        <CubaWrapper>
          {cubasData.map((el, index) => (
            <CubaColumn
              initial={initial}
              animate={animate}
              transition={{ duration: 0.5 + index * 0.1 }}
              key={index}
            >
              <CubaName>{el.name}</CubaName>
              <Zoom>
                <CarouselImage src={el.image} />
              </Zoom>
            </CubaColumn>
          ))}
        </CubaWrapper>
      </Container>
    </Section>
  );
};

export default Cubas;
