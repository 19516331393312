import React from "react";
import { Section } from "../../globalStyles";

const Slider = () => {
  return (
    <div margin-top="100px">
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="false"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="./assets/inspiracoes/cozinha/Cozinha_11.jpg"
              className="d-block w-100 mt-5"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Superfície Sólida Mineral</h5>
              <p>Trabalhamos com os melhores materiais do mercado.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="./assets/inspiracoes/cozinha/Cozinha_14.jpg"
              className="d-block w-100 mt-5"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Inspirações</h5>
              <p>
                Veja ideias e exemplos de como o produto ficará no seu ambiente.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="./assets/inspiracoes/banheiro/Banheiro_01.jpg"
              className="d-block w-100 mt-5"
              alt="..."
            />
            <div
              className="carousel-caption d-none d-md-block"
              color="rgba(0, 0, 0)"
            >
              <h5>Serviço</h5>
              <p>
                Atendimento e mão de obra qualificados, buscando sempre a
                satisfação do cliente.
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Slider;
