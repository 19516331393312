import React from "react";
import { Container, Section } from "../../globalStyles";
import {
  CubaText,
  CubaTitle,
  CubaWrapper,
  CubaColumn,
  CubaImageWrapper,
  CubaName,
  CubaTextWrapper,
  ImageWrapper,
  CarouselImage,
} from "./InspiracoesStyles";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  banheiroData,
  cozinhaData,
  inspiracoesData,
} from "../../data/InspiracoesData";

const Inspiracoes = () => {
  const initial = {
    y: 40,
    opacity: 0,
  };
  const animate = {
    y: 0,
    opacity: 1,
  };

  return (
    <Section smPadding="100px 10px" position="relative" inverse id="about">
      <Container>
        <CubaTextWrapper>
          <CubaTitle>Inspirações</CubaTitle>
        </CubaTextWrapper>
        <CubaWrapper>
          <CubaTitle>Banheiro</CubaTitle>
          {banheiroData.map((el, index) => (
            <CubaColumn
              initial={initial}
              animate={animate}
              transition={{ duration: 0.5 + index * 0.1 }}
              key={index}
            >
              <Zoom>
                <CarouselImage src={el.image} />
              </Zoom>
              {/* <CubaName>Descrição</CubaName> */}
            </CubaColumn>
          ))}
        </CubaWrapper>
        <CubaWrapper>
          <CubaTitle>Cozinha</CubaTitle>
          {cozinhaData.map((el, index) => (
            <CubaColumn
              initial={initial}
              animate={animate}
              transition={{ duration: 0.5 + index * 0.1 }}
              key={index}
            >
              <Zoom>
                <CarouselImage src={el.image} />
              </Zoom>
              {/* <CubaName>Descrição</CubaName> */}
            </CubaColumn>
          ))}
        </CubaWrapper>
      </Container>
    </Section>
  );
};

export default Inspiracoes;
