import React from "react";
import { Content } from "../components/Content/Content";
import {
  heroOne,
  heroTwo,
  heroThree,
  heroFour,
  heroFive,
  heroSix,
  heroSeven,
  heroAbout,
  heroContact,
} from "../data/HeroData";
import Slider from "../components/Slider/Slider";

const Home = () => {
  return (
    <>
      <Slider />
      <Content {...heroAbout} />
      <Content {...heroContact} />
      <Content {...heroOne} />
      <Content {...heroTwo} />
      <Content {...heroThree} />
      <Content {...heroFour} />
      <Content {...heroFive} />
      <Content {...heroSix} />
      <Content {...heroSeven} />
    </>
  );
};

export default Home;
