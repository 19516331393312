import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const iconStyle = (Icon) => <Icon />;

export const data = [
  {
    to: "/cubas",
    text: "Cubas",
  },
  {
    to: "/inspiracoes",
    text: "Inspirações",
  },
  {
    to: "/sobre",
    text: "Sobre",
  },
  // {
  //   to: "/contato",
  //   text: "Contato",
  // },
];

export const socialData = [
  {
    link: "https://www.instagram.com/resitech_/",
    icon: iconStyle(FaInstagram),
  },
  {
    link: "http://api.whatsapp.com/send?1=pt_BR&phone=5511974170964",
    icon: iconStyle(FaWhatsapp),
  },
  {
    link: "mailto:comercial@resitechsolidsurface.com.br",
    icon: iconStyle(FiMail),
  },
];
